import React from 'react'
import './../Services/service.css'
import './workProcess.css'

const WorkProcess = ()=>{
    return (
        <section className='service-style section-padding'>
            <div className="container">
                <div className="title-block">
                    <span className="tag-line">Proccess</span>
                    <h2>Working Proccess</h2>
                </div>
                <div className="working-process-wrap">
                        <div className="single-working-process">
                            <div className="count-block">
                                01
                            </div>
                            <h3>Pre Sale</h3>
                            <p>We provide site visit, solution design and provide an affordable budget</p>
                        </div>
                        <div className="single-working-process">
                            <div className="count-block">
                                02
                            </div>
                            <h3>Installation</h3>
                            <p>We do cabling, installation and verification of solutions</p>
                        </div>
                        <div className="single-working-process">
                            <div className="count-block">
                                03
                            </div>
                            <h3>Project Handover</h3>
                            <p>We provide product demos, ITC reports and warranty card for easy onboarding</p>
                        </div>
                        <div className="single-working-process">
                            <div className="count-block">
                                03
                            </div>
                            <h3>Service</h3>
                            <p>We provide complaint visit within 48 hours and half yearly mandatory visit</p>
                        </div>
                    </div>
            </div>
        </section>
    )
}

export default WorkProcess