import React from 'react'
import './about.css'
import image1 from './../../images/about_image.png'
import { BiCheckSquare } from 'react-icons/bi'

function About(){
    return (
        <React.Fragment>
            <section className='about-style section-padding' id='about_us'>
                <div className='container'>
                    <div className='about-section'>
                        <div className='single-about-sec'>
                            <div className='content-block'>
                                <div className="title-block">
                                    <span className="tag-line">About us</span>
                                    <h2>Who we are?</h2>
                                </div>
                                <p>Urmitech Solutions started its journey One year back with more than 12 years of experience 
                                    <br/>in Electronics Security Systems, with vision of providing safety. We are an end to end solution 
                                    <br/>provider with area of expertise in home security, where you can get execellent services for affordable prices.</p>
                                <div className='about-lists'>
                                    <ul className='list-item'>
                                        <li>
                                            <BiCheckSquare />
                                            CCTV Sales & Services
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Solar Panel and Inverter
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Biometric and Access Control
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Home Automation
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Video Conferencing
                                        </li>
                                    </ul>
                                    <ul className="list-item">
                                        <li>
                                            <BiCheckSquare />
                                            Networking and Wireless Solution
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            IDB Panel
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Electrical Services
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Video door phone
                                        </li>
                                        <li>
                                            <BiCheckSquare />
                                            Online UPS
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='single-about-sec'>
                        <div className="image-block">
                            <img alt="Image" src={image1} className= 'img-responsive' />
                        </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default About