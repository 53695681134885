import React from 'react'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import client1 from './../../images/client/la_cafe.png'
import client2 from './../../images/client/gpt.png'
import client3 from './../../images/client/retro.png'
import client4 from './../../images/client/romisys.png'
import client5 from './../../images/client/rajni.png'
import './client.css'

const Clients = ()=>{
    const clientSlider = {
        dots: false,
        autoplay:true,
        autoplaySpeed:2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
      };
    return(
        <section className='client-slider'>
            <div className="container">
                <Slider {...clientSlider}>
                    <div>
                        <img src={client1} />
                    </div>
                    <div>
                        <img src={client2} />
                    </div>
                    <div>
                        <img src={client3} />
                    </div>
                    <div>
                        <img src={client4} />
                    </div>
                    <div>
                        <img src={client5} />
                    </div>
                    <div>
                        <img src={client1} />
                    </div>
                    <div>
                        <img src={client2} />
                    </div>
                    <div>
                        <img src={client3} />
                    </div>
                    <div>
                        <img src={client4} />
                    </div>
                    <div>
                        <img src={client5} />
                    </div>
                </Slider>
            </div>
        </section>
    )
}
export default Clients