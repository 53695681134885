import React from "react";
import './service.css';
import { FaHome, FaGlobe, FaSolarPanel, FaBolt, FaCalendar, FaUsers } from 'react-icons/fa';
import CountUp from 'react-countup';

function Service(){
    return (
        <React.Fragment>
            <section className="service-style section-padding" id="services">
                <div className="container">
                    <div className="title-block">
                        <span className="tag-line">Services</span>
                        <h2> Services We Provide</h2>
                    </div>
                    <div className="services">
                        <div className="single-service">
                            <div className="icon-block">
                                <FaHome />
                            </div>
                            <h3>CCTV</h3>
                            <p>Sales and services for CCTV installation and support to residences, corporates and commercial places</p>
                        </div>
                        <div className="single-service">
                            <div className="icon-block">
                                <FaSolarPanel />
                            </div>
                            <h3>Solar</h3>
                            <p>Products including Solar Panels, Inverters and Batteries with installation, services and support</p>
                        </div>
                        <div className="single-service">
                            <div className="icon-block">
                                <FaGlobe />
                            </div>
                            <h3>Networking</h3>
                            <p>Data Networking, Wifi, Home Automation, Biometric and Smart lock installation</p>
                        </div>
                        <div className="single-service">
                            <div className="icon-block">
                                <FaBolt />
                            </div>
                            <h3>Electrical</h3>
                            <p>Electrical products supply with services to residences and commercial places</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="counter">
                <div className='counter-bg'>
                    <div className="container">
                        <div className="counter-info">
                            <div className="counter-details">
                                <div className='counter-icon-container'>
                                    <FaCalendar className='counter-icon'/>
                                </div>
                                <div className='counter-data'>
                                <h1><CountUp end={1} enableScrollSpy/>+</h1>
                                    <p>Years</p>
                                </div>
                            </div>
                            <div className="counter-details">
                                <div className='counter-icon-container'>
                                    <FaGlobe className='counter-icon'/>
                                </div>
                                <div className='counter-data'>
                                    <h1><CountUp end={50} enableScrollSpy/>+</h1>
                                    <p>Projects</p>
                                </div>
                            </div>
                            <div className="counter-details">
                                <div className='counter-icon-container'>
                                    <FaUsers className='counter-icon'/>
                                </div>
                                <div className='counter-data'>
                                <h1><CountUp end={40} enableScrollSpy/>+</h1>
                                    <p>Happy customers</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Service