import React from "react";
import './footer.css'
import logo from './../../images/logo/logo.png'
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaEnvelope, FaPhoneSquareAlt} from 'react-icons/fa';

export default function Footer (){
    return (
        <React.Fragment>
            <section className="footer-section" id="contact_us">
                <div className="main-footer">
                    <div className="container">
                        <div className="footer-content">
                            <div className="single-footer-col">
                                <a href="#">
                                    <img src={logo} className='footer-logo'/>
                                </a>
                                <p>We are committed to revolutionise your lifestyle with the help of latest technologies.</p>
                            </div>
                            <div className="single-footer-col">
                                <div className="footer-widget-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul>
                                    <li><a target= '_blank' href="https://www.facebook.com/profile.php?id=100090068111785&mibextid=ZbWKwL"><FaFacebookSquare/> Facebook</a></li>
                                    <li><a target= '_blank' href="https://instagram.com/urmitechsolutions?igshid=MmJiY2I4NDBkZg=="><FaInstagramSquare/> Instagram</a></li>
                                    <li><a target= '_blank' href="#"><FaLinkedin/> Linkedin</a></li>
                                </ul>
                            </div>
                            <div className="single-footer-col">
                                <div className="footer-widget-title">
                                    <h3>Contact Info</h3>
                                </div>
                                <ul>
                                    <li><a href='mailto:sales@urmitechsolutions.com'><FaEnvelope/> sales@urmitechsolutions.com</a></li>
                                    <li><a href='tel:+91 82602 11449'><FaPhoneSquareAlt/> +91 82602 11449</a></li>
                                </ul>
                            </div>
                            {/* <div className="single-footer-col">
                                <div className="footer-widget-title">
                                    <h3>NewsLetter</h3>
                                </div>
                                <div className="newsletter-form">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</p>
                                    <form action="">
                                        <input type="text" placeholder="Enter your Mail"/>
                                        <Button>Send</Button>
                                    </form>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="bottom-footer text-center">
                    <div className="container">
                        <p>Copyrights © {new Date().getFullYear()} Urmi TechSolutions. All right reserved.</p>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}