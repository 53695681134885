import React, { useState } from 'react';
import './Header.css'
import logo from './../../images/logo/logo.png'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaBars, FaEnvelope, FaPhoneSquareAlt} from 'react-icons/fa';


const Header = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
    return(
        <React.Fragment>
            <div className="header-navigation stricky-fixed">
                <div className='topbar'>
                    <div className='container'>
                        <div className='topbar-content'>
                            <div className='topbar-section'>
                                <a href='mailto:sales@urmitechsolutions.com'><FaEnvelope/> sales@urmitechsolutions.com</a>
                            </div>
                            <div className='topbar-section'>
                                <a href='tel:+91 82602 11449'><FaPhoneSquareAlt/> +91 82602 11449</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='navbar'>
                        <a href="#">
                            <img src={logo} className='header-logo'/>
                        </a>
                        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
                            <ul className="Header_links">
                                <li className="Header_link">
                                    <a href="#">Home</a>
                                </li>
                                <li className="Header_link">
                                    <a href="#about_us">About</a>
                                </li>
                                <li className="Header_link">
                                    <a href="#services">Services</a>
                                </li>
                                <li className="Header_link">
                                    <a href="#contact_us">Contact</a>
                                </li>
                            </ul>
                        </div>
                        <div className="right-side-box">
                            <div className="social">
                                <a target= '_blank' href="https://www.facebook.com/profile.php?id=100090068111785&mibextid=ZbWKwL">
                                    <FaFacebookF />
                                </a>
                                <a target= '_blank' href="https://instagram.com/urmitechsolutions?igshid=MmJiY2I4NDBkZg==">
                                    <FaInstagram />
                                </a>
                                <a target= '_blank' href="#">
                                    <FaLinkedinIn />
                                </a>
                            </div>
                        </div>
                        <button
                            className="hamburger"
                            onClick={() => {setIsNavExpanded(!isNavExpanded)}}
                        >
                            <FaBars/>
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Header