import React from "react";
import './banner.css'
import slider1 from './../../images/banner/slider-1.jpg'
import slider3 from './../../images/banner/slider-3.jpg'
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

function Banner (){
    return (
        <>
        <AwesomeSlider>
            <div data-src={slider1}>
                <p>NOTHING SECURES YOU BETTER THAN US!</p>
            </div>
            <div data-src={slider3}>
                <p>MORE SUSTAINABLE POWER SOURCE, MORE LIFE FOR THE PLANET!</p>
            </div>
            {/* <div data-src="/path/to/image-2.jpg" />  */}
        </AwesomeSlider> 
        </>
    )
}

export default Banner