import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './testimonial.css'
import icon1 from './../../images/testimonial/icon1.png'
import icon2 from './../../images/testimonial/icon2.png'

const Testimonial = ()=>{
    const testimonialSlider = {
        dots: true,
        fade: true,
        autoplay: true,
        infinite: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
      };
    return (
        <section className='testimonial section-padding' id='testimonial'>
            <div className='container'>
                <div className="title-block">
                    <span className="tag-line">Testimonials</span>
                    <h2>What Client Say</h2>
                </div>
                <Slider {...testimonialSlider}>
                    <div class="single-testimonials-one">
                        <div class="image-block">
                        <img alt="icon1" src={icon1}/>
                        </div>
                        <p>Excellent work with proper installation and service support. Understand customer requirements and Providing solution as per industrial standard</p>
                        <h3>MR SABIR RANJAN</h3>
                        <p class="designation">PLANT HEAD, MMG</p>
                    </div>
                    <div class="single-testimonials-one">
                        <div class="image-block">
                            <img alt="icon2" src={icon1}/>
                        </div>
                        <p>Professional work with good service support. Best price with quality product and installation</p>
                        <h3>MR UMAKANT</h3>
                        <p class="designation">OWNER OF RAJNI RESTAURANT & CAFE</p>
                    </div>
                    <div class="single-testimonials-one">
                        <div class="image-block">
                            <img alt="icon2" src={icon1}/>
                        </div>
                        <p>On time installation, On time service support, Professional approach, Quality installation</p>
                        <h3>MR RAJESH</h3>
                        <p class="designation">OWNER OF LACAFETHEQUE</p>
                    </div>
                </Slider>
            </div>
        </section>
    )
}

export default Testimonial