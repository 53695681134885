import logo from './logo.svg';
import './App.css';
import Header from './Components/Header/Header'
import Banner from './Components/Banner/Banner';
import Service from './Components/Services/Service';
import About from './Components/About/About';
import Footer from './Components/Footer/Footer';
import Testimonial from './Components/Testimonial/Testimonial';
import './Responsive.css';
import WorkProcess from './Components/WorkProcess/WorkProcess';
import Clients from './Components/Clients/Clients';

function App() {
  return (
    <div className="App">
      <Header/>
      <Banner/>
      <About />
      <Service />
      <WorkProcess/>
      <Testimonial/>
      <Clients/>
      <Footer />
    </div>
  );
}

export default App;
